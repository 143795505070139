export default {
  "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبا"])},
  "Clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملاء"])},
  "Doctors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاطباء"])},
  "Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
  "Client Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل العميل"])},
  "Add Client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة عميل"])},
  "Eidt Client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل عميل"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])}
}