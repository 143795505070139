<template>
    <SideBar />
    <div class="page">
        <div class="page-wrapper">
            <div class="container-xl">
                <!-- Page title -->
                <div class="page-header d-print-none">
                    <div class="row align-items-center">
                        <div class="col">
                            <!-- Page pre-title -->
                            <div class="page-pretitle">
                            </div>
                            <h2 class="page-title">
                                قائمة الحظر
                            </h2>
                        </div>
                        <!-- Page title actions -->
                        
                    </div>
                </div>
            </div>
            <div class="page-body">
			<div class="container-xl">

				<div class="col-12">
					<div class="card">
                        <SearchBar :dataUrl=searchUrl :legends=false />

						<div class="table-responsive">
							<table class="table card-table table-vcenter text-nowrap datatable table-hover">
								<thead>
									<tr>
										<th class="text-right">الاسم
											<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-sm text-dark icon-thick" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><polyline points="6 15 12 9 18 15" /></svg>
										</th>
										<th class="text-right">رقم الجواز</th>
                                        <th class="text-right">ملاحظة</th>
										<th class="text-right">آخر تعديل</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="client in clients" :key="client.id" >
										<td @click="$router.push({ name: 'detail_client', params: { id: client.id } })" class="text-right">
											{{ client.name }}
										</td>
										<td class="text-right">
											{{ client.passport_no}}
										</td>
										<td>
                                            {{ client.notes }}
                                        </td>
										<td class="text-right">
											{{ client.modified }}
										</td>
										<td class="text-start">
										
											<div class="btn-list flex-nowrap">
                                                <button class="btn d-sm-inline-block"
                                                    @click="$router.push({ name: 'detail_client', params: { id: client.id } })">
                                                    <span class="mx-1">عرض</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        class="icon icon-tabler icon-tabler-arrow-left" width="24"
                                                        height="24" viewBox="0 0 24 24" stroke-width="2"
                                                        stroke="currentColor" fill="none" stroke-linecap="round"
                                                        stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <line x1="5" y1="12" x2="19" y2="12"></line>
                                                        <line x1="5" y1="12" x2="11" y2="18"></line>
                                                        <line x1="5" y1="12" x2="11" y2="6"></line>
                                                    </svg>
                                                </button>
												<button class="btn d-sm-inline-block" @click="unblock_client(client.id)">
													<span class="mx-1">الغاء الحظر</span>
													<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>   <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>   <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>   <path d="M16 5l3 3"></path></svg>
                                                </button>
												
											</div>
										</td>
									</tr>
									
								</tbody>
							</table>
						</div>
			
                         <!-- pagination -->
                         <PaginationBar :pagination_data=pagination_data />
					</div>
				</div>

			</div>
        </div>
            <FooterBar />
            <!-- footer -->
        </div>
    </div>
</template>


<style>
.users .dropdown-menu {
    inset: auto auto 0px 0px;

}
</style>

<script>
import PaginationBar from '@/components/PaginationBar.vue';
import SearchBar from '@/components/SearchBar.vue';
import SideBar from '@/components/SideBar.vue';
import FooterBar from '@/components/FooterBar.vue';
import { formate_datetime } from '@/script.js'

export default {
    components: {
        PaginationBar,
        SearchBar,
        SideBar,
        FooterBar,
        },
    setup() {
        return { formate_datetime }
    },
    data() {
        return {
            // tasks
            clients: [],
            dataUrl: '/api/client/blocked_clients/',
            searchUrl: '/admin/block-list/',
            pagination_data: {},
            urlArgs: '',
        }
    },

    methods: {


        async getData(url) {

            const response = await this.axios.get(url);
            // set the data returned as tasks
            if (response) {
                this.clients = response.data.results;
                this.pagination_data = response.data;
                const urlParams = new URL(response.request.responseURL);
                this.urlArgs = urlParams.search

            }
            if (this.$store.getters.getHttpError) this.msg = this.$store.getters.getHttpError.message;


        },

        async unblock_client(id){
            if (confirm("الغاء حظر العميل؟") != true) return false;

            const url = `/api/client/${id}/unblock/`;
                
            await this.axios.post(url, {
                    status: 1,
                    }).then(() => {
                    //Perform Success Action
                    if (this.$store.getters.getHttpError) {
                        let error_obj = this.$store.getters.getHttpError
                        this.msg = error_obj.message;
                        if (error_obj.response.data) this.form_errors = error_obj.response.data;

                    }
                    this.clients = this.clients.filter(client => client.id !== id)

                });
    },

    },

    async created() {
        this.urlArgs = window.location.search;
        this.getData(this.dataUrl + this.urlArgs);
    },



};

</script>