import moment from 'moment';

export function removeTime(date) {

    if (date instanceof Date) return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return date;

}

export function formate_datetime(date) {
    const a = moment(date);
    const check = a.isValid();
    if (check) return moment(date).format('YYYY-MM-DDTHH:mm:ss');
    return date;


}

export function isTauri() {
    return typeof window !== 'undefined' && window.__TAURI__ !== undefined;
}

export function get_date(date)
{
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  }).format(new Date(date));
  return formattedDate;
}