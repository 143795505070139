<template>

    <span v-click-away="onClickAway" class="dropdown">
        <button @click="toggle" class="btn dropdown-toggle align-text-top" aria-expanded="false">{{ count }}</button>
        <div  :class="active ? 'show' : ''" class="dropdown-menu dropdown-menu-end">
        <slot>
         </slot>
            
        </div>
    </span>
</template>

<script>

export default {
    props: ['count'],
    data() {
        return {

            active: false,
        }
    },
    methods:
    {

        toggle() {
            this.active = !this.active
        },
        onClickAway() {
            if (this.active) this.active = false;
        }

    },

};
</script>