<template>
<div class="card-body border-bottom py-3">
    <div class="d-flex">
        <small v-if="legends">
                            <span class="badge bg-green me-2">جاهز</span>
                            <!-- Add more badges as needed -->
                            <span class="badge bg-warning me-2">تحت الموافقة</span>
                            <span class="badge bg-danger me-2">محظور</span>
                            <span class="badge bg-info me-2">ملاحظة نشطة</span>


        </small>

        <div class="ms-auto text-muted">
             <form v-on:submit.prevent="searchForm">
            <div class="ms-2 d-inline-block">
                <input class="form-control form-control-sm" v-model="searchTerm" :placeholder="$t('Search')+'...'" aria-label="Search" />
            </div>
                <button class="btn btn-sm btn-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" class="" width="12" height="17" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="10" cy="10" r="7"></circle><line x1="21" y1="21" x2="15" y2="15"></line></svg>
                </button>
             </form>
        </div>
        
    </div>
</div>
</template>
<script>

export default {
  props: ['dataUrl', 'legends'],
    
    data() {
        return {
          
        searchTerm:'',

        }
    },
methods: {
           
        searchForm()
        {
            if(this.searchTerm)
            {
                let dataUrl = this.dataUrl.replace("/api", '');
                this.$router.push(`${dataUrl}?search=${this.searchTerm}`);
                
            }
        },

    },
    created()
    {
        this.searchTerm=this.$route.query.search;
    },
    watch: { 
     '$route.query.search': function(search) {
           if(!search) this.searchTerm="";
           

      }
    }

}

</script>
