<template>


<div class="col-12">


<div class="card">
    <div class="card-header">
        <h3 class="card-title">الملاحظات الداخلية</h3>
        <div class="card-actions">

        
        <a href="#" @click="showModal = true" class="btn btn-light">
            <!-- Download SVG icon from http://tabler-icons.io/i/plus -->
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.5"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-notes"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 3m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z" /><path d="M9 7l6 0" /><path d="M9 11l6 0" /><path d="M9 15l4 0" /></svg>
            ملاحظة جديدة
        </a>
    </div>
    </div>
    <div class="list-group list-group-flush list-group-hoverable">
        <div class="list-group-header">النشطة</div>
        
            <div v-for="internalnote in active_internalnotes" class="list-group-item"  :key="internalnote.id">
            <InternalNoteItem @status-updated="reloadInternalNote"  :internalnote="internalnote"></InternalNoteItem>

            </div>
        <div class="list-group-header">المنتهية</div>

        <div v-for="internalnote in inactive_internalnotes" class="list-group-item"  :key="internalnote.id">
            <InternalNoteItem @status-updated="reloadInternalNote"  :internalnote="internalnote"></InternalNoteItem>

            </div>

           
    </div>
</div>

   
    <ModalWindow :visible="showModal" @close="showModal = false">
      
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="internalNoteModalLabel">أضف ملاحظة</h5>
            <button @click="showModal = false" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="noteText" class="form-label">الملاحضة</label>
                <textarea v-model="form.note" class="form-control" id="noteText" rows="3" :class="form_errors.note ? 'is-invalid' : ''" />
                <div class="invalid-feedback">{{
                form_errors.note?.[0]
                }}
                </div>
            </div>
            <div class="mb-3">
              <label for="noteStatus" class="form-label">الحالة</label>
              <select v-model="form.status" class="form-select" id="noteStatus" :class="form_errors.marital_status ? 'is-invalid' : ''" >
                <option selected value="1">نشطة</option>
                <option value="0">غير نشطة</option>
              </select>
              <div class="invalid-feedback">{{ form_errors.marital_status?.[0]
                                                        }}</div>

            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary ms-auto" @click="addInternalNote">اضافة</button>
            <button class="btn" @click="showModal = false" data-bs-dismiss="modal">الغاء</button>

          </div>
        </div>
      
      
    </ModalWindow>
      
</div>

</template>




<script>
import ModalWindow from '@/components/ModalWindow.vue';
import InternalNoteItem from '@/components/InternalNoteItem.vue';
export default {
    components: {
    ModalWindow,
    InternalNoteItem,
  },
  props: {
    clientId: {
        type: Number,
        required: true
      }
    },
    data() {
        return {
            showModal: false,
            form:{
                note: '',
                status: 1,
                client: this.clientId
            },
            form_errors:[],
            internalnotes:[],
            activeDropDown:false,
        }
    },

    computed: {
        active_internalnotes() {
            return this.internalnotes.filter(note => note.status === 1);
        },
        inactive_internalnotes(){
            return this.internalnotes.filter(note => note.status === 0);

        }
    },
    methods:
    {
        
        reloadInternalNote(){
            this.getData();

        },
        async getData() {

        const response = await this.axios.get(`/api/internal-note/?client=${this.clientId}`);


        if (response) this.internalnotes = response.data;

       
        },

        
        async addInternalNote() {
            const url = '/api/internal-note/';
            await this.axios.post(url, this.form).then(() => {
                //Perform Success Action
                if (this.$store.getters.getHttpError) {
                    let error_obj = this.$store.getters.getHttpError
                    this.msg = error_obj.message;
                    if (error_obj.response.data) this.form_errors = error_obj.response.data;

                }
                else{
                    this.showModal = false;

                    this.getData();
                }
                
                // else this.$router.replace({ name: 'client', })
            });
        },
    },
    async created() {
        this.getData();

    },
}

</script>
