<template>

    <div class="page page-center">
        <div class="container-tight py-4">
            <div class="text-center mb-4"><img src="@/assets/logo.png" height="80" alt="" class="navbar-brand-autodark">
            </div>
            <form v-if="!is_authintcated" v-on:submit.prevent="submitForm" class="card card-md" autocomplete="off">
                <div class="card-body">
                    <h2 class="card-title text-center mb-4">معاينة تقرير طبي</h2>
                    <div class="mb-3"><label class="form-label">ID</label>
                        <input :class="form_errors.detail ? 'is-invalid' : ''" v-model="form.issue_uid" type="number"
                            class="form-control mb-2" placeholder="أدخل معرّف التقرير المكون من 14 أرقام">
                    </div>
                    <div class="mb-2"><label class="form-label">PIN</label>
                        <input type="number" :class="form_errors.detail ? 'is-invalid' : ''" v-model="form.pin" 
                            class="form-control mb-2" placeholder="أدخل رمز الأمان المكون من 5 أرقام"
                            autocomplete="off">
                        <div class="invalid-feedback">{{ form_errors.detail }}</div>
                    </div>
                    <div class="form-footer"><button type="submit" class="btn btn-primary w-100">معاينة</button></div>
                </div>
            </form>
            <div v-else class="card card-md">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 text-center">
                    <img class="avatar avatar-xl" style="height: auto;" :src="issue.client_photo">
                  </div>
                  <div class="col-12 mt-4">
                    <h4 class="card-title m-0 mb-3">
                      الاسم: {{ issue.client_name }}
                    </h4>
                    <h4 class="card-title m-0">
                      رقم الجوال: {{ issue.client_mobile}}
                    </h4>
                    <h4 class="card-title m-0">
                      تاريخ الفحص: {{ issue.report_date }}
                    </h4>
                    <h4 class="card-title m-0">
                      اسم المكتب: {{ issue.office_name }}
                    </h4>
                    <h4 class="card-title m-0">
                      رقم الطلب: {{ issue.application_no_out }}
                    </h4>
        
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>


<style>
    /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}



</style>

<script>

export default {
    components: {

    },
    data() {
        return {
            form: {
                issue_uid: '',
                pin: '',
            },
            form_errors: {
                detail: ''
            },

            issue: {},

        }

    },
    computed:{
        is_authintcated(){
            return this.$store.getters.getReportPin !='';
        }

    },
    methods:
    {
        async submitForm() {
            if (!this.form.issue_uid || !this.form.pin) {this.form_errors.detail = "الكود ورمز التحقق لايمكن ان يكونا فارغين!"; return;}

            const authReportPin = this.form.pin;
            await this.axios.post(`/api/report-issue/${this.form.issue_uid || 0}/report_out/`,{ pin: authReportPin })
                    .then((response) => {                   
                    if (this.$store.getters.getHttpError) {

                        let error_obj = this.$store.getters.getHttpError
                        if (error_obj.response.data) this.form_errors = error_obj.response.data;

                    }

                    else {
                            this.$store.dispatch('authReportPin', authReportPin);
                            this.issue = response.data
                           
                        }                
                    }); 
        },

    },

    async created() {
        this.form.issue_uid = this.$route.params.uid;
        this.$store.dispatch('authReportPin', '');

    },
}
</script>
