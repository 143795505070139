<template>



    <div class="card-footer d-flex align-items-center">
        <p class="m-0 text-muted">عرض  من <span>{{ indexDataFrom }}</span> إلى <span>{{ indexDataTo }}</span> من مجموع <span>{{
                pagination_data.count
        }}</span> </p>
        <ul class="pagination m-0 ms-auto">
            <li class="page-item" :class="previousUrl ? '' : 'disabled'">
                <a class="page-link" href="#" @click.prevent="loadPrev()" tabindex="-1"
                    :aria-disabled="previousUrl ? 'false' : 'true'">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <polyline points="9 6 15 12 9 18" />
                    </svg>
                    السابق
                </a>
            </li>

            <li class="page-item" :class="nextUrl ? '' : 'disabled'">
                <a class="page-link" href="#" @click.prevent="loadNext()"
                    :aria-disabled="nextUrl ? 'false' : 'true'">التالي
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <polyline points="15 6 9 12 15 18" />
                    </svg>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    props: ['pagination_data', 'urlArgs'],

    data() {
        return {

            nextUrl: '',
            previousUrl: '',
            indexDataFrom:"",
            indexDataTo:"",
            results_count:"",

        }
    },
    methods: {
        get_queryString(url){
            const url_obj = new URL(url);
            const queryString = url_obj.search;
            return queryString
        },
        loadNext() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            const queryString = this.get_queryString(this.nextUrl)
            this.$router.push(queryString, { silent: true });
            this.getIndexingDetails(this.nextUrl);
            

        },
        loadPrev() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            const queryString = this.get_queryString(this.previousUrl)
            this.$router.push(queryString, { silent: true });
            this.getIndexingDetails(this.nextUrl);
        },

        getIndexingDetails(url)
        {
            if(url){
            let offset = this.$route.query.offset;
            let limit = this.$route.query.limit;
            this.indexDataFrom = offset
            this.indexDataTo = limit
            this.pagination_data.count


            }
        }
    },

    watch: {
        // You can also set up a watcher for name here if you like
        pagination_data() {
            this.results_count = this.pagination_data.results?.length;

            let offset = parseInt(this.$route.query.offset || 0)+1;
            let limit = parseInt(this.$route.query.limit || this.results_count );
            this.indexDataFrom = offset;
            this.indexDataTo = (offset + limit - 1) > this.pagination_data.count ? this.pagination_data.count : (offset + limit - 1)
            this.pagination_data.count

            this.nextUrl = this.pagination_data.next;
            this.previousUrl = this.pagination_data.previous;
            
        }
    }
}

</script>