<template>
    <SideBar />
    <div class="page">
        <div class="page-wrapper">
            <div class="container-xl">
                <!-- Page title -->
                <div class="page-header d-print-none">
                    <div class="row align-items-center">
                        <div class="col">
                            <!-- Page pre-title -->
                            <div class="page-pretitle">
                            </div>
                            <h2 class="page-title">
                                قائمة المستخدمين
                            </h2>
                        </div>
                        <!-- Page title actions -->
                        <div class="col-auto ms-auto d-print-none">
                            <div class="btn-list">
                                <router-link to="/admin/users/add/" class="btn btn-primary  d-sm-inline-block">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                    إضافة مستخدم جديد
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-body">
                <div class="container-xl">

                    <div class="col-12">
                        <div class="card">
                            <!-- <div class="card-header"> -->
                            <!-- <h3 class="card-title">العملاء</h3> -->
                            <!-- </div> -->
                            <SearchBar :dataUrl=dataUrl />

                            <div class="table-responsive">
                                <table
                                    class="table card-table table-vcenter text-nowrap datatable table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th class="text-right">اسم المستخدم
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-sm text-dark icon-thick" width="24" height="24"
                                                    viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <polyline points="6 15 12 9 18 15" />
                                                </svg>
                                            </th>
                                            <th class="text-right">الاسم الكامل</th>
                                            <th class="text-right">حالة المستخدم</th>
                                            <th class="text-right">الدور</th>

                                            <th class="text-right">التحقق بخطوتين</th>
                                            <th class="text-right">آخر دخول</th>
                                            <th class="text-right">وقت الإنشاء</th>
                                            <th class="text-right">الهاتف</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="user in users" :key="user.id">
                                            <td class="text-right">
                                                {{ user.username }}
                                            </td>
                                            <td class="text-right">
                                                {{ user.first_name }} {{ user.last_name }}
                                            </td>
                                            <td class="text-right">
                                                <template v-if="user.is_active">
                                                    <span class="badge bg-success me-1 align-middle"></span>
                                                    نشط
                                                </template>
                                                <template v-else>
                                                    <span class="badge bg-danger me-1 align-middle"></span>
                                                    غير نشط
                                                </template>
                                            </td>
                                            <td class="text-right">
                                                {{ user.role_display }}
                                            </td>

                                            <td class="text-right">
                                                <template v-if="user.two_step_ver">
                                                    <span class="badge bg-success me-1 align-middle"></span>
                                                    نشط
                                                </template>
                                                <template v-else>
                                                    <span class="badge bg-danger me-1 align-middle"></span>
                                                    غير نشط
                                                </template>

                                            </td>
                                            <td class="text-right">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-tabler icon-tabler-calendar-event text-muted"
                                                    width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                                    stroke="currentColor" fill="none" stroke-linecap="round"
                                                    stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <rect x="4" y="5" width="16" height="16" rx="2"></rect>
                                                    <line x1="16" y1="3" x2="16" y2="7"></line>
                                                    <line x1="8" y1="3" x2="8" y2="7"></line>
                                                    <line x1="4" y1="11" x2="20" y2="11"></line>
                                                    <rect x="8" y="15" width="2" height="2"></rect>
                                                </svg>
                                                {{ formate_datetime(user.last_login) }}

                                            </td>
                                            <td class="text-right">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-tabler icon-tabler-calendar-event text-muted"
                                                    width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                                    stroke="currentColor" fill="none" stroke-linecap="round"
                                                    stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <rect x="4" y="5" width="16" height="16" rx="2"></rect>
                                                    <line x1="16" y1="3" x2="16" y2="7"></line>
                                                    <line x1="8" y1="3" x2="8" y2="7"></line>
                                                    <line x1="4" y1="11" x2="20" y2="11"></line>
                                                    <rect x="8" y="15" width="2" height="2"></rect>
                                                </svg>
                                                {{ formate_datetime(user.date_joined) }}
                                            </td>
                                            <td class="text-right" style="direction: ltr; text-align: right">
                                                {{ user.mobile }}
                                            </td>
                                            <td class="text-start">

                                                <div class="btn-list flex-nowrap">

                                                    <button
                                                        @click="$router.push({ name: 'edit_user', params: { id: user.id } })"
                                                        class="btn d-sm-inline-block">
                                                        <span class="mx-1">تعديل</span>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            class="icon icon-tabler icon-tabler-edit" width="24"
                                                            height="24" viewBox="0 0 24 24" stroke-width="2"
                                                            stroke="currentColor" fill="none" stroke-linecap="round"
                                                            stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <path
                                                                d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1">
                                                            </path>
                                                            <path
                                                                d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z">
                                                            </path>
                                                            <path d="M16 5l3 3"></path>
                                                        </svg>
                                                    </button>


                                                    <!-- dropdown -->
                                                    <DropDown class="users" count="إجراءات أخرى">

                                                        <button class="dropdown-item"
                                                            @click="$router.push(`/admin/logs/?actor=${user.id}`)">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                class="icon icon-tabler icon-tabler-history" width="24"
                                                                height="24" viewBox="0 0 24 24" stroke-width="2"
                                                                stroke="currentColor" fill="none" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none">
                                                                </path>
                                                                <polyline points="12 8 12 12 14 14"></polyline>
                                                                <path d="M3.05 11a9 9 0 1 1 .5 4m-.5 5v-5h5"></path>
                                                            </svg>
                                                            <span class="mx-1">سجل الحركة</span>
                                                        </button>
                                                        <button v-if="user.two_step_ver" @click="reset2fa(user)"
                                                            class="dropdown-item" data-bs-toggle="modal"
                                                            data-bs-target="#modal-reset2fa">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                class="icon icon-tabler icon-tabler-2fa" width="24"
                                                                height="24" viewBox="0 0 24 24" stroke-width="2"
                                                                stroke="currentColor" fill="none" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none">
                                                                </path>
                                                                <path d="M7 16h-4l3.47 -4.66a2 2 0 1 0 -3.47 -1.54">
                                                                </path>
                                                                <path d="M10 16v-8h4"></path>
                                                                <line x1="10" y1="12" x2="13" y2="12"></line>
                                                                <path d="M17 16v-6a2 2 0 0 1 4 0v6"></path>
                                                                <line x1="17" y1="13" x2="21" y2="13"></line>
                                                            </svg>
                                                            <span class="mx-1">إعادة تعيين التحقق بخطوتين</span>
                                                        </button>
                                                    </DropDown>

                                                </div>
                                            </td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>
                            <!-- pagination -->
                            <PaginationBar :pagination_data=pagination_data />
                        </div>
                    </div>

                </div>
            </div>
            <FooterBar />
            <!-- footer -->
        </div>
    </div>
</template>


<style>
.users .dropdown-menu {
    inset: auto auto 0px 0px;

}
</style>

<script>
import PaginationBar from '@/components/PaginationBar.vue';
import SearchBar from '@/components/SearchBar.vue';
import SideBar from '@/components/SideBar.vue';
import FooterBar from '@/components/FooterBar.vue';
import DropDown from '@/components/DropDown.vue';
import { formate_datetime } from '@/script.js'

export default {
    components: {
        PaginationBar,
        SearchBar,
        SideBar,
        FooterBar,
        DropDown
    },
    setup() {
        return { formate_datetime }
    },
    data() {
        return {
            // tasks
            users: [],
            dataUrl: '/api/user/',
            pagination_data: {},
            urlArgs: '',
        }
    },

    methods: {

        async reset2fa(user) {
            if (confirm("سيتم اعادة ضبط جهاز التحقق بخطونين!") != true) return false;

            await this.axios.patch(this.dataUrl + user.id + '/', { 'reset2fa': user.id }).then(() => {
                //Perform Success Action
                if (this.$store.getters.getHttpError) {
                    let error_obj = this.$store.getters.getHttpError
                    if (error_obj.response.data) this.form_errors = error_obj.response.data;

                }
                else user.two_step_ver = false;

            });

        },
        async getData(url) {

            const response = await this.axios.get(url);
            // set the data returned as tasks
            if (response) {
                this.users = response.data.results;
                this.pagination_data = response.data;
                const urlParams = new URL(response.request.responseURL);
                this.urlArgs = urlParams.search

            }
            if (this.$store.getters.getHttpError) this.msg = this.$store.getters.getHttpError.message;




        },

    },
    async created() {
        this.urlArgs = window.location.search;
        this.getData(this.dataUrl + this.urlArgs);
    },



};

</script>