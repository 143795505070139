export default {
  login(credentials, axios) {
    return axios
      .post('/api-token-auth/', credentials)
      .then(response => response);

  },
  logout(axios) {
    return axios
      .post('/logout/')
      .then(response => response);

  },
  
  device_confirm(credentials, axios) {
    return axios
      .post(`/api/totp/login/${credentials.otp}/`,{}, {
        headers: {
          Authorization: `token ${credentials.token}`
        }
      }
      )
      .then(response => response);
  },
  add_device(credentials, axios) {
    return axios
      .get('/api/totp/create/', {
        headers: {
          Authorization: `token ${credentials.token}`
        }
      }
      )
      .then(response => response?.data);
  },
};