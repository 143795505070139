<template>
    <SideBar />
    <div class="page">
        <div class="page-wrapper">
            <div class="container-xl">
                <!-- Page title -->
                <div class="page-header d-print-none">
                    <div class="row align-items-center">
                        <div class="col">
                            <!-- Page pre-title -->
                            <div class="page-pretitle">
                            </div>
                            <h2 class="page-title">
                                قائمة العينات
                            </h2>
                        </div>
                        <!-- Page title actions -->
                        <!-- <div class="col-auto ms-auto d-print-none">
                            <div class="btn-list">
                                <router-link to="/report/add/" class="btn btn-primary d-sm-inline-block">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                    إضافة فحص جديد
                                </router-link>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="page-body">
                <div class="container-xl">

                    <div class="col-12">
                        <div class="card">
                            <SearchBar :dataUrl=dataUrl />
                            <!-- search -->
                            <div v-if="samples.length" class="table-responsive  overflow-visible">
                                <table
                                    class="table card-table table-vcenter text-nowrap datatable table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th class="w-1">#</th>
                                            <th>كود العينة
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-sm text-dark icon-thick" width="24" height="24"
                                                    viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <polyline points="6 15 12 9 18 15" />
                                                </svg>
                                            </th>

                                            <th class="text-center">تاريخ العينة</th>
                                            <th class="w-1"></th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="sample in samples" :key="sample.id">
                                            <td><span class="text-muted">{{ sample.id }}</span></td>
                                            <td>

                                                {{ sample.blood_test_uid }}
                                            </td>

                                           
                                            <td class="text-center">{{ sample.created }}</td>
                                           

                                            <td>
                                                <div class="btn-list flex-nowrap">
                                                  

                                               
                                                  
                                                   
                                                <button  v-if="sample.has_report == 0 && (role == 1 || role == 5)" 
                                                        @click="$router.push({ name: 'add_report_for_blood_sample', params: {
                                                            height_blood_sample_uid : sample.blood_test_uid } })"
                                                        class="btn-sm btn btn-primary">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                                            stroke-linecap="round" stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <line x1="12" y1="5" x2="12" y2="19"></line>
                                                            <line x1="5" y1="12" x2="19" y2="12"></line>
                                                        </svg>
                                                        إضافة فحص
                                                    </button>
                                                    <button 
                                                    @click="open_sample_code(sample)"
                                                        class="btn-sm btn btn-primary">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-test-pipe-2" width="44" 
                                                        height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" 
                                                        stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M15 3v15a3 3 0 0 1 -6 0v-15"></path><path d="M9 12h6"></path><path d="M8 3h8"></path>
                                                        </svg>
                                                            كود العينة
                                                    </button>
                                                    <button
                                                    @click="$router.push({ name: 'edit_height_client', params: { id: sample.client, 
                                                        blood_test_uid:sample.blood_test_uid } })"
                                                    class="btn d-sm-inline-block">
                                                    <span class="mx-1">تعديل</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        class="icon icon-tabler icon-tabler-edit" width="24" height="24"
                                                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path
                                                            d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1">
                                                        </path>
                                                        <path
                                                            d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z">
                                                        </path>
                                                        <path d="M16 5l3 3"></path>
                                                    </svg>
                                                </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- pagination -->
                            <PaginationBar :pagination_data=pagination_data />
                        </div>
                    </div>

                </div>
            </div>
            <FooterBar />
            <!-- footer -->
        </div>
    </div>

</template>

<script>
import PaginationBar from '@/components/PaginationBar.vue';
import SearchBar from '@/components/SearchBar.vue';
import FooterBar from '@/components/FooterBar.vue';
import SideBar from '@/components/SideBar.vue';



export default {
    components: {
        PaginationBar,
        SearchBar,
        FooterBar,
        SideBar,
    },
    computed: {
        role() {
            return this.$store.getters.getUser.role;
             }
        },
    data() {
        return {
            // tasks
            samples: [],
            msg: '',
            dataUrl: '/api/sample/',
            pagination_data: '',
            urlArgs: '',

        }
    },

    methods: {

        open_sample_code(sample)
        {
            let url = this.$router.resolve({ name: 'examination_label', params: { height_blood_sample_uid: sample.blood_test_uid} });
            window.open(url.href, '_blank');

        },

        async getData(url) {

            const response = await this.axios.get(url);
            // set the data returned as tasks
            if (response) {
                this.samples = response.data.results;
                this.pagination_data = response.data;
                const urlParams = new URL(response.request.responseURL);
                this.urlArgs = urlParams.search

            }
            if (this.$store.getters.getHttpError) this.msg = this.$store.getters.getHttpError.message;




        },


    },
    async created() {
        this.urlArgs = window.location.search;
        this.getData(this.dataUrl + this.urlArgs);

    },




};

</script>