<template>
    <div class="page page-center">
      <div class="container-tight py-4">
        <div class="text-center mb-4">
          <img src="@/assets/logo.png" height="80" alt="" class="navbar-brand-autodark">
        </div>
        <form class="card card-md"  v-on:submit.prevent autocomplete="off">

          <div class="card-body">
            <h2 class="card-title text-center mb-4">الدخول إلى النظام</h2>
            <div v-if="form_errors" class="invalid-feedback">{{ form_errors.non_field_errors }}</div>
            <div v-if="!token" class="mb-3">
              <label class="form-label">اسم المستخدم</label>
              <input type="text" class="form-control mb-2" :class="form_errors.username ? 'is-invalid' : ''"
                placeholder="أدخل اسم المستخدم" v-model="username" />
              <div v-if="form_errors" class="invalid-feedback">{{ form_errors.username?.[0] }}</div>
  
            </div>
            <div v-if="!token" class="mb-2">
              <label class="form-label">كلمة المرور</label>
              <input type="password" class="form-control mb-2"  :class="form_errors.password ? 'is-invalid' : ''" 
              placeholder="أدخل كلمة المرور" autocomplete="off" v-model="password" />
              <div v-if="form_errors" class="invalid-feedback">{{ form_errors.password?.[0] }}</div>
            </div>

            <div v-if="token && !is_add_device" class="mb-3">
              <label class="form-label">رمز OTP </label>
              <input type="text" class="form-control mb-2" :class="form_errors.otp ? 'is-invalid' : ''"
                v-model="otp" />
              <div v-if="form_errors" class="invalid-feedback">{{ form_errors.otp?.[0] }}</div>
  
            </div>
            
            <div v-if="qr" class="mb-3">
              <vue-qrcode :value="qr" :height="200"></vue-qrcode>

            </div>

            <div class="form-footer">
              <button v-if="!token" type="submit" @click="login" class="is-invalid btn btn-primary w-100">دخول</button>
              <button v-if="token && !is_add_device" type="submit" @click="device_confirm" class="btn btn-primary w-100">دخول</button>
              <button v-if="is_add_device" type="submit" @click="add_device" class="btn btn-primary w-100">اضف جهاز+</button>
              <div v-if="form_errors" class=" invalid-feedback">{{ form_errors.non_field_errors }}</div>



            </div>
          </div>
        </form>
      </div>
    </div>
  </template>
  <script>
  import AuthService from '@/services/AuthService.js';
  import VueQrcode from 'vue-qrcode';

export default {
	components: { VueQrcode },
    data() {
      return {
        username: '',
        password: '',
        otp:'',
        form_errors:[],
        token: '',
        user:{},
       

        is_add_device:false,
        qr:'',
      };
    },
    methods: {
      async login() {
          this.$store.dispatch('logout');

          const credentials = {
            username: this.username,
            password: this.password
          };
          const response = await AuthService.login(credentials, this.axios);
           if (this.$store.getters.getHttpError) 
              {
                let error_obj = this.$store.getters.getHttpError
                if(error_obj.response.data) this.form_errors = error_obj.response.data;
              
              }
          else
          {
          this.token = response.data.token;
          
          this.is_add_device = response.data.is_add_device;
            
          }
      },
      async device_confirm() {
        
        const credentials = {
          token: this.token,
          otp: this.otp,

        };
        const response = await AuthService.device_confirm(credentials, this.axios);
         if (this.$store.getters.getHttpError) 
            {
              let error_obj = this.$store.getters.getHttpError
              if(error_obj.response.data) this.form_errors = error_obj.response.data;
            
            }
        else
        {
          this.user = response.data.user;
          this.$store.dispatch('login', { token: this.token, user: this.user });
          this.$router.push('/');
          
        }
    },
    async add_device() {
        
        const credentials = {
          token: this.token,

        };
        const response = await AuthService.add_device(credentials, this.axios);
         if (this.$store.getters.getHttpError) 
            {
              let error_obj = this.$store.getters.getHttpError
              if(error_obj.response.data) this.form_errors = error_obj.response.data;
            
            }
        else
        {
          this.qr = response;
          this.is_add_device=false;
          
        }
    }
    }
  };
  </script>