<template>
    <footer class="footer footer-transparent d-print-none">
        <div class="container-xl">
            <div class="row text-center align-items-center">
            <ul class="list-inline list-inline-dots mb-0">
                <li class="list-inline-item">
                حقوق الطبع محفوظة &copy; {{ currentYear }}
                <a href="https://saberhospital.com" class="link-secondary">مستشفى صابر</a>.
                </li>
            </ul>
            </div>
        </div>
    </footer>
</template>


<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  }
};
</script>