<template>
    <SideBar />

    <div class="page">
        <div class="page-wrapper">
            <div class="container-xl">
                <!-- Page title -->
                <div class="page-header d-print-none">
                    <div class="row align-items-center">
                        <div class="col">
                            <!-- Page pre-title -->
                            <div class="page-pretitle">
                            </div>
                            <h2 class="page-title">
                                إدارة عمليات التعديل
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-body">
                <div class="container-xl">

                    <div class="col-12">
                        <div class="card">
                            <!-- <div class="card-header"> -->
                            <!-- <h3 class="card-title">العملاء</h3> -->
                            <!-- </div> -->
                            <div class="card-body border-bottom py-3">
                                <div class="d-flex">
                                    <!-- <div class="text-muted"> -->
                                    <!-- عرض -->
                                    <!-- <div class="mx-2 d-inline-block"> -->
                                    <!-- <input type="text" class="form-control form-control-sm" value="10" size="3" aria-label=""> -->
                                    <!-- </div> -->
                                    <!-- عملاء -->
                                    <!-- </div> -->

                                </div>
                            </div>
                            <div class="table-responsive scroll-x">
                                <table class="table table-vcenter text-nowrap">
                                    <thead>
                                        <tr>
                                            <th class="text-right">العنصر</th>
                                            <th class="text-right">نوع العنصر</th>
                                            <th class="text-right">الحالة</th>
                                            <th class="text-right">بواسطة</th>
                                            <th class="text-right">تاريخ التعديل</th>


                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                   
                                            <ModerationItem  v-for="moderation_object in moderation_objects"
                                            :key="moderation_object.id" :moderation_object=moderation_object />

                                    </tbody>
                                </table>
                            </div>
                            <!-- pagination -->
                            <PaginationBar :pagination_data=pagination_data />
                        </div>
                    </div>

                </div>
            </div>
            <FooterBar />
            <!-- footer -->
        </div>
    </div>
</template>
<script>
import FooterBar from '@/components/FooterBar.vue';
import SideBar from '@/components/SideBar.vue';
import PaginationBar from '@/components/PaginationBar.vue';
import ModerationItem from '@/components/ModerationItem.vue';

export default {
    components: {
    FooterBar,
    SideBar,
    PaginationBar,
    ModerationItem
},
    data() {
        return {
            moderation_objects: [],
            dataUrl: '/api/moderation/',
            pagination_data: {},
            urlArgs: '',
        }
    },

    methods: {

       

        async getData(url) {

            const response = await this.axios.get(url);
            // set the data returned as tasks
            if (response) {
                this.moderation_objects = response.data.results;
                this.pagination_data = response.data;
                const urlParams = new URL(response.request.responseURL);
                this.urlArgs = urlParams.search

            }
            if (this.$store.getters.getHttpError) this.msg = this.$store.getters.getHttpError.message;




        },

    },
    async created() {
        this.urlArgs = window.location.search;
        this.getData(this.dataUrl + this.urlArgs);
    },




};

</script>
      