<template>
    <SideBar />

    <div class="page">
        <div class="page-wrapper">
            <div class="container-xl">
                <!-- Page title -->
                <div class="page-header d-print-none">
                    <div class="row align-items-center">
                        <div class="col">
                            <!-- Page pre-title -->
                            <div class="page-pretitle">
                            </div>
                            <h2 v-if="form.id" class="page-title">
                                طول عميل
                            </h2>

                        </div>
                    </div>
                </div>
            </div>
            <div class="page-body">
                <div class="container-xl">
                    <div class="row row-cards">
                        <div class="col-12">
                            <form v-on:submit.prevent="submitForm" class="card card-md">
                                <div v-if="form_errors" class="invalid-feedback">{{ form_errors.non_field_errors }}
                                </div>
                                <div class="card-header">
                                    <h4 class="card-title">بيانات العميل </h4>
                                </div>
                                <div class="card-body">
                                    <div v-if="form?.moderation_status == 2" class="row">
                                        <div class="col-12">
                                            <div class="alert alert-info alert-dismissible" role="alert">
                                                <div class="d-flex">
                                                    <div>
                                                        <!-- Download SVG icon from http://tabler-icons.io/i/info-circle -->
                                                        <!-- SVG icon code with class="alert-icon" -->
                                                    </div>
                                                    <div>
                                                        <h4 class="alert-title">تحت الموافقة</h4>
                                                        <div class="text-muted">هذا العنصر لازال يتطلب موافقة المدير على
                                                            التعديلات</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">

                                        <div class="col-12 col-lg-6">
                                            <label class="form-label">{{ form.name }}</label>
                                            <fieldset class="form-fieldset col-12 mb-4">
                                                <div class="row divide-y">
                                                    <div class="col-12 mb-3">
                                                        <label class="form-label">طول العميل</label>
                                                        <div class="input-group mb-2">
                                                            <input class="form-control text-right"
                                                                :class="form_errors.height ? 'is-invalid' : ''"
                                                                v-model="form.height" type="number" />
                                                            <span class="input-group-text">
                                                                سنتيمتر
                                                            </span>
                                                            <div class="invalid-feedback">{{ form_errors.height?.[0] }}
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </fieldset>

                                        </div>


                                    </div>


                                </div>

                                <div class="card-footer text-end">
                                    <div class="d-flex order-reversed">
                                        <a href="#" onclick="history.back()" class="btn">إلغاء</a>
                                        <button :disabled="!isFormChanged" type="submit" class="btn btn-primary ms-auto">حفظ </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <FooterBar />
            <!-- footer -->
        </div>
    </div>
</template>

<script>




import FooterBar from '@/components/FooterBar.vue';
import SideBar from '@/components/SideBar.vue';

// import * as utilis from '@/script.js';


export default {
    components: {
        FooterBar,
        SideBar,

    },
    data() {
        return {

            form: {
                blood_test_uid: '',
                height: '',
                client: '',
                has_report:false,

            },

            msg: '',
            form_errors: [],
            initialForm: null,

        }
    },
    computed: {
        isFormChanged() {
        console.log(this.form);
        console.log(this.initialForm);

        return JSON.stringify(this.form) !== JSON.stringify(this.initialForm);
        }
    },
    methods: {

        async submitForm() {
            const headers = { 'Content-Type': 'multipart/form-data' };
            let post_method = this.axios.post;
            let url = `/api/sample/`
            
            if (this.form.blood_test_uid) {
                post_method = this.axios.put;
                url = `/api/sample/${this.form.blood_test_uid}/`
            }
            await post_method(url, this.form, { headers }).then((response) => {
                //Perform Success Action
                if (this.$store.getters.getHttpError) {
                    let error_obj = this.$store.getters.getHttpError
                    this.msg = error_obj.message;
                    if (error_obj.response.data) this.form_errors = error_obj.response.data;

                }

                // else this.$router.replace('/client/');
                else this.$router.replace({ name: 'examination_label', params: { height_blood_sample_uid: response.data.blood_test_uid} })
            });


        },
        async getData() {

            if (this.form.blood_test_uid) {
                const response = await this.axios.get(`/api/sample/${this.form.blood_test_uid}/`);
                // set the data returned as tasks
                if (response) 
                {
                    this.form.height = response.data.height;
                    this.form.moderation_status = response.data.moderation_status;
                    this.form.has_report = response.data.has_report;

                }
            }
            if (this.$store.getters.getHttpError) this.msg = this.$store.getters.getHttpError.message;

        },
    },
    async created() {
        this.form.blood_test_uid = this.$route.params.blood_test_uid;
        this.form.client = this.$route.params.id;
        await this.getData();
        this.initialForm = JSON.parse(JSON.stringify(this.form));



    },
}
</script>
