<template>
    <tbody>
      <slot v-for="item in items" :key="item.id" :item="item"></slot>
    </tbody>
  </template>
  
  <script>
  export default {
    props: {
     id: {
        default: null,
      },
      apiEndpoint: {
        type: String,
        required: true,
      },

      initialOffset: {
        type: Number,
        default: 0,
      },
      container: {
      type: Object,
      default: null,
    },
    },
    data() {
      return {
        items: [],
        offset: this.initialOffset,
        loading: false,
        allLoaded: false,
        limit : 0,
      };
    },
    watch: {
    container(newContainer) {
      if (newContainer) {
        this.attachScrollListener(newContainer);
      }
    }
  },
  mounted() {
    if (this.container) {
      this.attachScrollListener(this.container);
    }
    this.loadItems();
  },
  beforeUnmount() {
    if (this.container) {
      this.container.removeEventListener('scroll', this.handleScroll);
    }
  },
  methods: {
    attachScrollListener(container) {
      container.addEventListener('scroll', this.handleScroll);
    },
      async loadItems() {
        if (this.allLoaded || this.loading) return;
        this.loading = true;
        try {
          const response = await this.axios.get(this.apiEndpoint, {
            params: {
              offset: this.offset,
              id: this.id,
            },
          });
          const data = response.data;
          if (!this.limit) this.limit = data.results.length;

          this.items = [...this.items, ...data.results];

          if (data.count <= this.limit || data.results.length < this.limit) {
            this.allLoaded = true;
          } else {
            this.offset += this.limit;
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      },
      handleScroll() {
        const scrollContainer = this.container;
        if (
            scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight - 10 &&
            !this.loading
        ) {
            this.loadItems();
        }
        },
    },
  };
  </script>
  
  <style>
  .infinite-scroll-container {
    overflow-y: scroll;
    border: 1px solid #ccc;
  }
  .loading {
    text-align: center;
    padding: 20px;
  }
  </style>
  