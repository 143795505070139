<template>
<div class="row align-items-center">
    <div class="col-auto">
        <span class="badge" :class="internalnote.status === 1 ? 'bg-red' : 'bg-blue'"></span>
    </div>                            
    
    <div class="col">
        {{ internalnote.note}}                        
        <div class="text-secondary">{{ formate_datetime(internalnote.created) }}</div>
    </div>
    <div class="col-auto">
        <div v-click-away="onClickAway" class="dropdown">
            <a @click="toggle"  class="btn-action" data-bs-toggle="dropdown" aria-expanded="false">
        <!-- Download SVG icon from http://tabler-icons.io/i/dots-vertical -->
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path><path d="M12 19m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path><path d="M12 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path></svg>
            </a>
            <div  :class="activeDropDown ? 'show' : ''" class="dropdown-menu dropdown-menu-end" style="">

                <a v-if="internalnote.status==1" href="#" @click="change_status" class="dropdown-item text-primary">إنهاء الملاحظة</a>
                <a v-else href="#" @click="change_status" class="dropdown-item text-warning">تنشيط الملاحظة</a>

            </div>
        </div>
    </div>
</div>

</template>
<script>
import  { formate_datetime } from '@/script.js'

export default {
    props: ['internalnote'],
    setup(){
      return { formate_datetime }
    },
    data() {
        return {

            activeDropDown: false,
        }
    },
    methods:
    {
                
        async change_status() {
            const newStatus = this.internalnote.status === 1 ? 0 : 1;

            const url = `/api/internal-note/${this.internalnote.id}/`;
            await this.axios.patch(url, {
                status: newStatus,
                }).then(() => {
                //Perform Success Action
                if (this.$store.getters.getHttpError) {
                    let error_obj = this.$store.getters.getHttpError
                    this.msg = error_obj.message;
                    if (error_obj.response.data) this.form_errors = error_obj.response.data;

                }

                // else this.$router.replace({ name: 'client', })
                this.$emit('status-updated');
            });

        },

        toggle() {
            this.activeDropDown = !this.activeDropDown
        },
        onClickAway() {
            if (this.activeDropDown) this.activeDropDown = false;
        }

    },

};
</script>

<style scoped>
.dropdown-item, .dropdown-menu {
    min-width: auto;
}
</style>