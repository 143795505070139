<template>
	<body class="A4 ThermalLabel" style="overflow: hidden; padding-top: 0;">
		<!-- "padding-**mm" is optional: you can set 10, 15, 20 or 25 -->
		<section style="padding-top: 0;" class="sheet">
			<article style="padding-top: 0;">
				<table style="border:3pt solid #000; margin: 0; font-family: 'Arial'">
					<tr>
						<td style="padding: 0">
							<p style="font-size: 10pt; margin: 0" class="nowrap dir-ltr text-bold">
							SABERTESTS.COM
							<br />
							ID: {{ get_issue_uid(blood_test_uid) }}
							</p>
						</td>
					</tr>
					
					
				</table>
				<div class="printPageButton d-print-none">
					<button class="btn" onclick="window.print();return false;">طباعة</button>
					<button class="btn" @click="goBackOrClose">عودة</button>
				</div>
			</article>
		</section>
	</body>
</template>


<style scoped>
  .printPageButton {
	height: 0.984in;
  }
  


body {
    width: 1.514in; 
}

table {
  text-align: center;
  width: 1.514in;
  height: 0.984in;
}
	.A4 {
			display: flex;
			justify-content: center;
			font-family: 'Traditional Arabic';
			font-weight: bold;
		}
		hr {
			margin: 0;
		}
		.center-text {
			text-align: center;
		}
		.left-text {
			text-align: left;
		}
		.right-text {
			text-align: right;
		}
		.align-top {
			vertical-align: top;
		}
		.subtitle {
			font-size: 14pt;
			font-weight: bold;
		}
		td {
			padding: 0 5pt;
		}
		.bt-0 {
			border-left: 0;
		}
		.bb-0 {
		border-bottom: 0;
		}
		.br-0 {
			border-right: 0;
		}
		.bl-0 {
			border-left: 0;
		}
		.nowrap {
			white-space: nowrap;
		}
		.dir-ltr {
			direction: ltr;
		}
		.text-bold {
			font-weight: bold;
		}
		.english-font {
			font-family: 'Times New Roman';
			font-size: 10pt;
		}
		.font-16 {
			font-size: 16pt;
		}
		.font-14 {
			font-size: 14pt;
		}
</style>

<script>

export default {

    data() {
        return {
            blood_test_uid: "",
            id: null,
			qr_code:"",
        }
    },
    methods:
    {
		goBackOrClose() {
			if (window.history.length > 1) {
				window.history.back();
			} else {
				window.close();
			}
		},
		get_issue_uid(val)
		{
			return  val.match(/.{1,3}/g).join('-');   
		},
        async getData() {

            const response = await this.axios.get(`/api/sample/${this.id}/`);

           

            if (response) {
                this.blood_test_uid = response.data.blood_test_uid.toString();

				this.qr_code =  response.data.qr_code
				
            }

            // set the data returned as tasks

        },

		
    },
    async created() {
		this.id = this.$route.params.height_blood_sample_uid;

        this.getData();


    },
};
</script>