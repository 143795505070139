<template>
  <SideBar />

  <div class="page">
    <div class="page-wrapper">
      <div class="container-xl">
        <!-- Page title -->
        <div class="page-header d-print-none">
          <div class="row align-items-center">
            <div class="col">
              <!-- Page pre-title -->
              <div class="page-pretitle">
              </div>
              <h2 v-if="form.id" class="page-title">
                تعديل طبيب
              </h2>
              <h2 v-else class="page-title">
                إضافة طبيب جديد
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="page-body">
        <div class="container-xl">
          <div class="row row-cards">
            <div class="col-12">
              <form v-on:submit.prevent="submitForm" class="card card-md">
                <div v-if="form_errors" class="invalid-feedback">{{ form_errors.non_field_errors }}</div>
                <div class="card-header">
                  <h4 class="card-title">بيانات الطبيب </h4>
                </div>
                <div class="card-body">
                  <div v-if="form?.moderation_status == 2" class="row">
                    <div class="col-12">
                      <div class="alert alert-info alert-dismissible" role="alert">
                        <div class="d-flex">
                          <div>
                            <!-- Download SVG icon from http://tabler-icons.io/i/info-circle -->
                            <!-- SVG icon code with class="alert-icon" -->
                          </div>
                          <div>
                            <h4 class="alert-title">تحت الموافقة</h4>
                            <div class="text-muted">هذا العنصر لازال يتطلب موافقة المدير على التعديلات</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">

                    <div class="col-12 col-lg-6">
                      <label class="form-label">البيانات الأساسية</label>
                      <fieldset class="form-fieldset col-12 mb-4">
                        <div class="row divide-y">
                          <div class="col-12 mb-3">
                            <label class="form-label">الاسم</label>
                            <input class="form-control" :class="form_errors.name ? 'is-invalid' : ''"
                              v-model="form.name" placeholder="الأسم" />
                            <div class="invalid-feedback">{{ form_errors.name?.[0] }}</div>
                          </div>
                          <div class="col-12 mb-3">
                            <label class="form-label">مركز الطبيب </label>
                            <input class="form-control" :class="form_errors.title ? 'is-invalid' : ''"
                              v-model="form.title" placeholder=" مركز الطبيب" />
                            <div class="invalid-feedback">{{ form_errors.title?.[0] }}</div>
                          </div>


                        </div>
                      </fieldset>

                    </div>

                  </div>


                </div>

                <div class="card-footer text-end">
                  <div class="d-flex order-reversed">
                    <!-- <a href="#" class="btn btn-link">إلغاء</a> -->
                    <button type="submit" class="btn btn-primary ms-auto">حفظ </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <FooterBar />
      <!-- footer -->
    </div>
  </div>
</template>

<script>
import FooterBar from '@/components/FooterBar.vue';
import SideBar from '@/components/SideBar.vue';

export default {
  components: {
    FooterBar,
    SideBar
  },
  data() {
    return {
      form: {
        id: '',
        name: '',
        title: '',
        moderation_status: ''
      },

      msg: '',
      form_errors: []
    }
  },

  methods: {

    async submitForm() {
      let post_method = this.axios.post;
      let url = `/api/doctor/`
      if (this.form.id) {
        post_method = this.axios.put;
        url = `/api/doctor/${this.form.id}/`
      }

      await post_method(url, this.form).then(() => {
        //Perform Success Action
        if (this.$store.getters.getHttpError) {
          let error_obj = this.$store.getters.getHttpError
          this.msg = error_obj.message;
          if (error_obj.response.data) this.form_errors = error_obj.response.data;

        }

        else this.$router.replace('/doctor/');
      });


    },
    async getData() {

      // set the data returned as tasks
      if (this.form.id) {
        const response = await this.axios.get(`/api/doctor/${this.form.id}/`);
        // set the data returned as tasks
        if (response) this.form = response.data;
      }
      if (this.$store.getters.getHttpError) this.msg = this.$store.getters.getHttpError.message;

    },
  },
  async created() {
    this.form.id = this.$route.params.id;
    this.getData();


  },
}
</script>
