<template>
      <div v-if="isLoading" class="loading-indicator">
    <img src="/imgs/animation.gif" alt="Loading..." />
  </div>
  </template>
  
  <script>
  export default {
    computed: {
      isLoading() {
        return this.$store.getters.isLoading; // Accessing isLoading state from Vuex store
      }
    }
  }
  </script>
  
  <style>

  .loading-indicator {
  /* Center the loading indicator */
  display: flex;
  justify-content: center;
  align-items: center;
  /* Optional styles to overlay on top of other content */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
}
.loading-indicator img {
  /* Optional styles for the loading GIF */
  width: 100px; /* Adjust the width as needed */
}

</style>
