<template>
    <transition name="slide-fade">

    <tr v-if="finish_mod" :class="item_class">

        <td class="text-right">
            {{ moderation_object.changed_object }}
        </td>
        <td class="text-right">
            {{ moderation_object.content_type }}
        </td>

        <td class="text-right">
            {{ moderation_object.status }}
        </td>
        <td class="text-right">
            {{ (moderation_object.modified_by || moderation_object.by) }}
        </td>
        <td class="text-right">
            {{ formate_datetime(moderation_object.updated) }}
        </td>
      
        <td>
            <div class="btn-list flex-nowrap">
                <button @click="dropdownToggle()" class="btn btn-light d-sm-inline-block" aria-expanded="false"
                    role="tab">
                    <span class="">عرض تفاصيل التعديل..</span>
                </button>

                <a @click="approve(moderation_object.id)" class="btn btn-primary d-sm-inline-block">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-check" width="24"
                        height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none">
                        </path>
                        <path d="M5 12l5 5l10 -10"></path>
                    </svg>
                    <span class="">قبول التعديل</span>
                </a>
                <a class="btn btn-outline-danger d-sm-inline-block"
                    @click="reject(moderation_object.id)">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-ban" width="24"
                        height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none">
                        </path>
                        <circle cx="12" cy="12" r="9"></circle>
                        <line x1="5.7" y1="5.7" x2="18.3" y2="18.3"></line>
                    </svg>
                    <span class="">رفض</span>
                </a>
            </div>
        </td>
    </tr>
</transition>

    <tr v-if="finish_mod">
        <td colspan="6" class="p-0">
            <div class="card card-sm accordion-collapse collapse  " :class="{'show': isDropActive}" id="edit-view-1"
                role="tabpanel" style="">
                <div class="card-status-start bg-blue"></div>
                <div class="card-body">
                    <div class="">
                        <table class="table table-borderless w-50">
                            <thead>
                                <tr>
                                    <td></td>
                                    <td>القيمة السابقة</td>
                                    <td class="fw-bold">القيمة الجديدة</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="value, key in moderation_object.change_list" :key="key">
                                    <td v-if="key === 'notes' || key === 'modified_by'">
                                        <span v-if="value[1][0] != 'None' && value[1][1]">{{ value[0] }}</span>
                                    </td>

                                    <td v-else>{{ value[0] }}</td>
                                    <template v-if="key === 'modified'">
                                        <td><span class="special-tag">{{ formate_datetime(value[1][0]) }}</span></td>
                                        <td class="fw-bold"><span class="special-tag">{{ formate_datetime(value[1][1]) }}</span></td>
                                    </template>
                                    <template v-else-if="key === 'photo'">
                                        <td><span v-if="value[0]" class="photo-tag"><img :src="value[1][0]"/></span></td>
                                        <td class="fw-bold"><span v-if="value[1]" class="photo-tag"><img :src="value[1][1]"/></span></td>
                                    </template>
                                     <template v-else-if="key === 'notes' || key === 'modified_by'">
                                        <template v-if="value[1][0] != 'None' && value[1][1]">
                                        <td>{{ value[1][0] }}</td>
                                        <td class="fw-bold">{{ value[1][1] }}</td>
                                        </template>
                                    </template>

                                    <template v-else>
                                        <td>{{ value[1][0] }}</td>
                                        <td class="fw-bold">{{ value[1][1] }}</td>
                                    </template>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </td>
    </tr>
</template>

<script>
import  { formate_datetime } from '@/script.js'

export default {
    props: ['moderation_object'],
    setup(){
      return { formate_datetime }
    },
    data() {
        return {
            finish_mod: true,
            isDropActive: false,
            post_method : this.axios.patch,
            url : '/api/moderation/',
            item_class:null,
        }
    },
    methods:
    {

        async approve(id) {
            if (confirm("الموافقة على التعديلات؟") != true) return false;
            this.item_class = "bg-blue";

            await this.post_method(this.url+id+'/',{'approve':id}).then(() => {
                //Perform Success Action

                if (this.$store.getters.getHttpError) {
                    let error_obj = this.$store.getters.getHttpError
                    if (error_obj.response.data) this.form_errors = error_obj.response.data;

                }
                else {
                    
                    this.finish_mod = false;

                }

            });


        },
        async reject(id) {

            if (confirm("إلغاء التعديلات؟") != true) return false;
            this.item_class = "bg-red";

            await this.post_method(this.url+id+'/',{'reject':id}).then(() => {

               //Perform Success Action
               if (this.$store.getters.getHttpError) {
                   let error_obj = this.$store.getters.getHttpError
                   if (error_obj.response.data) this.form_errors = error_obj.response.data;

               }
               else {
                    this.finish_mod = false;

                }


           });


       },

        dropdownToggle() {
            this.isDropActive = !this.isDropActive;
        },

    },

};
</script>

<style scoped>
.slide-fade-enter-active, .slide-fade-leave-active {
    transition: all 0.5s ease;
}
.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */ {
    transform: translateY(0);
    opacity: 1;
}
.slide-fade-leave-active {
    transform: translateY(-100%);
    opacity: 0;
}
</style>