<template>
    <SideBar />

    <div class="page">
        <div class="page-wrapper">
            <div class="container-xl">
                <!-- Page title -->
                <div class="page-header d-print-none">
                    <div class="row align-items-center">
                        <div class="col">
                            <!-- Page pre-title -->
                            <div class="page-pretitle">
                            </div>
                            <h2 v-if="form.id" class="page-title">
                                تعديل عميل
                            </h2>
                            <h2 v-else class="page-title">
                                إضافة عميل جديد
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-body">
                <div class="container-xl">
                    <div class="row row-cards">
                        <div class="col-12">
                            <form v-on:submit.prevent="submitForm" class="card card-md">
                                <div v-if="form_errors" class="invalid-feedback">{{ form_errors.non_field_errors }}
                                </div>
                                <div class="card-header">
                                    <h4 class="card-title">بيانات العميل </h4>
                                </div>
                                <div class="card-body">
                                    <div v-if="form?.moderation_status == 2" class="row">
                                        <div class="col-12">
                                            <div class="alert alert-info alert-dismissible" role="alert">
                                                <div class="d-flex">
                                                    <div>
                                                        <!-- Download SVG icon from http://tabler-icons.io/i/info-circle -->
                                                        <!-- SVG icon code with class="alert-icon" -->
                                                    </div>
                                                    <div>
                                                        <h4 class="alert-title">تحت الموافقة</h4>
                                                        <div class="text-muted">هذا العنصر لازال يتطلب موافقة المدير على
                                                            التعديلات</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 col-lg-6">
                                            <div class="row">
                                                <div class="col-12">
                                                    <label class="form-label">بيانات المكتب
                                                    </label>

                                                    <fieldset class="office-details form-fieldset col-12 mb-4" ref="officeDetails">
                                                        <div class="row divide-y">
                                                            <div class="col-12 mb-3">
                                                                <label class="form-label">رقم الطلب</label>
                                                                <input name="application_no"  class="form-control"
                                                                    :class="form_errors.application_no ? 'is-invalid' : ''"
                                                                    v-model="form.application_no" placeholder="رقم الطلب" />
                                                                <div class="invalid-feedback">{{ form_errors.application_no?.[0] }}</div>
                                                            </div>
                                                            <div class="col-12 mb-3">
                                                                <label class="form-label">رقم الخطاب</label>
                                                                <input name="letter_no"  class="form-control"
                                                                    :class="form_errors.letter_no ? 'is-invalid' : ''"
                                                                    v-model="form.letter_no" placeholder="رقم الخطاب" />
                                                                <div class="invalid-feedback">{{ form_errors.letter_no?.[0] }}</div>
                                                            </div>
                                                            <div class="col-12 mb-3">
                                                                <label class="form-label">تاريخ الخطاب</label>
                                                                <input name="letter_date"  type="date" class="form-control"
                                                                    :class="form_errors.letter_date ? 'is-invalid' : ''"
                                                                    v-model="form.letter_date" />
                                                                <div class="invalid-feedback">{{ form_errors.letter_date?.[0] }}</div>
                                                            </div>
                                                            <div class="col-12 mb-3">
                                                                <label class="form-label">اسم المكتب</label>
                                                                <input name="office_name" class="form-control"
                                                                    :class="form_errors.office_name ? 'is-invalid' : ''"
                                                                    v-model="form.office_name" placeholder="اسم المكتب" />
                                                                <div class="invalid-feedback">{{ form_errors.office_name?.[0] }}</div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>                                            
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <label class="form-label">ملاحظات</label>

                                                    <fieldset class="form-fieldset col-12 mb-4">
                                                        <div class="row divide-y">
                                                            <div class="col-12 mb-3">
                                                                <textarea class="form-control"
                                                                    :class="form_errors.notes ? 'is-invalid' : ''"
                                                                    v-model="form.notes" />

                                                                <div class="invalid-feedback">{{
                                                                form_errors.notes?.[0]
                                                                }}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </fieldset>
                                                </div>                                            
                                            </div>
                                           
                                        </div>
                 

                                        <div class="col-12 col-lg-6">
                                            <label class="form-label">البيانات الأساسية</label>
                                            <fieldset class="form-fieldset col-12 mb-4">
                                                <div class="row divide-y">
                                                    <div class="col-12 mb-3">
                                                        <label class="form-label">الاسم</label>
                                                        <input class="form-control"
                                                            :class="form_errors.name ? 'is-invalid' : ''"
                                                            v-model="form.name" placeholder="الأسم" />
                                                        <div class="invalid-feedback">{{ form_errors.name?.[0] }}</div>
                                                    </div>
                                                    <div class="col-12 mb-3">
                                                        <label class="form-label">تاريخ الميلاد</label>
                                                        <input type="date" class="form-control"
                                                            :class="form_errors.dob ? 'is-invalid' : ''"
                                                            v-model="form.dob" />
                                                        <div class="invalid-feedback">{{ form_errors.dob?.[0] }}</div>
                                                    </div>
                                                    <div class="col-6 mb-3">
                                                        <label class="form-label">الحالة الاجتماعية</label>
                                                        <select class="form-control"
                                                            :class="form_errors.marital_status ? 'is-invalid' : ''"
                                                            v-model="form.marital_status"
                                                            placeholder="الحالةالإجتماعية ..">
                                                            <option value="" disabled hidden>الحالة الإجتماعية ..
                                                            </option>
                                                            <option v-for="choice in marital_status_choices"
                                                                :value="choice.value" :key="choice.value">{{
                                                                choice.display_name }}</option>
                                                        </select>
                                                        <div class="invalid-feedback">{{ form_errors.marital_status?.[0]
                                                        }}</div>
                                                    </div>
                                                    <div class="col-6 mb-3">
                                                        <label class="form-label">الجنسية</label>
                                                        <select class="form-control"
                                                            :class="form_errors.nationality ? 'is-invalid' : ''"
                                                            v-model="form.nationality">
                                                            <option value="" disabled hidden>الجنسية ..</option>
                                                            <option v-for="choice in nationality_choices"
                                                                :value="choice.value" :key="choice.value">{{
                                                                choice.display_name }}</option>
                                                        </select>
                                                        <div class="invalid-feedback">{{ form_errors.nationality?.[0] }}
                                                        </div>
                                                    </div>
                                                    <div class="col-6 mb-3">
                                                        <label class="form-label">المهنة</label>
                                                        <input class="form-control"
                                                            :class="form_errors.occupation ? 'is-invalid' : ''"
                                                            v-model="form.occupation" placeholder="المهنة" />
                                                        <div class="invalid-feedback">{{ form_errors.occupation?.[0]}}
                                                        </div>
                                                    </div>
                                                    <div class="col-6 mb-3">
                                                        <label class="form-label">رقم الهاتف</label>
                                                        <input class="form-control"
                                                            :class="form_errors.mobile ? 'is-invalid' : ''"
                                                            v-model="form.mobile" placeholder="رقم الهاتف" />
                                                        <div class="invalid-feedback">{{ form_errors.mobile?.[0]}}</div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-label">الصورة الشخصية للعميل</div>
                                                        <input ref="file" type="file" class="form-control"
                                                            :class="form_errors.photo ? 'is-invalid' : ''"
                                                            @change="editImage" accept="image/*">
                                                        <div class="invalid-feedback">{{ form_errors.photo?.[0]}}</div>
                                                        <a v-if="form.photo && !form.photo?.name" :href="form.photo"
                                                            target="_blank">{{ form.photo }}</a>

                                                    </div>

                                                </div>
                                            </fieldset>

                                            <label class="form-label">جواز العميل</label>
                                            <fieldset class="form-fieldset col-12 mb-4">
                                                <div class="row">
                                                    <div class="col-12 col-lg-6 mb-3">
                                                        <label class="form-label">رقم الجواز</label>
                                                        <input class="form-control"
                                                            :class="form_errors.passport_no ? 'is-invalid' : ''"
                                                            v-model="form.passport_no" placeholder="رقم الجواز" />
                                                        <div class="invalid-feedback">{{ form_errors.passport_no?.[0]}}
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-lg-6 mb-3">
                                                        <label class="form-label">مكان الإصدار</label>
                                                        <select class="form-control"
                                                                    :class="form_errors.place_of_issue ? 'is-invalid' : ''"
                                                                    v-model="form.place_of_issue">
                                                            <option value="" disabled hidden>مكان الإصدار ..</option>
                                                            <option v-for="(option, index) in placeOfIssueOptions" :key="index" :value="option.value">
                                                                {{ option.label }}
                                                            </option>
                                                            <option value="other">أضف مكان اخر</option>
                                                            </select>
                                                            <input v-if="form.place_of_issue === 'other' || form.place_of_issue === customPlace"
                                                                class="form-control"
                                                                v-model="customPlace"
                                                                :class="form_errors.place_of_issue ? 'is-invalid' : ''"
                                                                placeholder="ادخل مكان الاصدار ...">
                                                        <div class="invalid-feedback">{{ form_errors.place_of_issue?.[0]
                                                        }}</div>
                                                    </div>
                                                    <div class="col-12 mb-3">
                                                        <label class="form-label">تاريخ الإصدار</label>
                                                        <input type="date" class="form-control"
                                                            :class="form_errors.date_of_issue ? 'is-invalid' : ''"
                                                            v-model="form.date_of_issue" />
                                                        <div class="invalid-feedback">{{
                                                        form_errors.date_of_issue?.[0]}}</div>
                                                    </div>
                                                </div>
                                            </fieldset>


                                        </div>

                                    </div>
                                    
                                    
                                
                                    
                                </div>

                                <div class="card-footer text-end">
                                    <div class="d-flex order-reversed">
                                        <a href="#" onclick="history.back()" class="btn">إلغاء</a>
                                        <button :disabled="!isFormChanged" type="submit" class="btn btn-primary ms-auto">حفظ </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <FooterBar />
            <!-- footer -->
        </div>
    </div>
</template>

<script>




import FooterBar from '@/components/FooterBar.vue';
import SideBar from '@/components/SideBar.vue';

import * as utilis from '@/script.js';


export default {
    components: {
        FooterBar,
        SideBar,

    },
    computed: {
        isFormChanged() {
        return JSON.stringify(this.form) !== JSON.stringify(this.initialForm);
        }
    },
    data() {
        return {
            customPlace:'',

            placeOfIssueOptions: [],
            form: {
                name: '',
                dob: '',
                passport_no: '',
                date_of_issue: '',
                place_of_issue: 'عدن',
                nationality: 'YE',
                marital_status: 0,
                occupation: '',
                mobile: '',
                photo: '',
                moderation_status: '',
                
                letter_date:'',
                application_no:'',
                letter_no:'',
                office_name:'',
                notes:'',
            },
            nationality_choices: [],
            marital_status_choices: [],
            // place_of_issue_choices: [],

            msg: '',
            form_errors: [],

            initialForm: null,

        }
    },

    methods: {
        set_custom_place_of_issue_value(){
            const valueExists = this.placeOfIssueOptions.some(option => option.value === this.form.place_of_issue);
                    if (!valueExists){
                        this.placeOfIssueOptions.push({ value: this.form.place_of_issue, label: this.form.place_of_issue })
                }

                this.customPlace = '';
        },
        office_details_is_valid(){
            const officeDetailsDiv = this.$refs.officeDetails;
            const inputs = officeDetailsDiv.querySelectorAll('input');
            const allFieldsAreEmpty = [...inputs].every(input => input.value.trim() === '');
            const allFieldsHaveValues = [...inputs].every(input => input.value.trim() !== '');
            
            if (!allFieldsAreEmpty && !allFieldsHaveValues) {
                this.$notify({

                text: "يجب ادخال بيانات المكتب جميعها معا!",
                type: 'error',

                });
                return false;
            }
                

            return true
        },
        editImage() {
            this.form.photo = this.$refs.file.files[0];
            console.log(this.form.photo);

        },
        async submitForm() {
            
            if (!this.office_details_is_valid()) return;

            const headers = { 'Content-Type': 'multipart/form-data' };
            this.form.date_of_issue = utilis.removeTime(this.form.date_of_issue);
            this.form.dob = utilis.removeTime(this.form.dob);
            this.form.letter_date = utilis.removeTime(this.form.letter_date);

            
            if (this.form.place_of_issue === 'other') {
                // Handle custom place submission
                if (!this.customPlace.trim()) {
                    this.form_errors.place_of_issue =   ['يرجى إدخال المكان'];
                    return;
                }
                // Set the value of place_of_issue to customPlace
                this.form.place_of_issue = this.customPlace;
            }
      
            let post_method = this.axios.post;
            let url = `/api/client/`
            if (this.form.id) {
                post_method = this.axios.put;
                url = `/api/client/${this.form.id}/`
            }
            if (!this.form.photo?.name) delete this.form.photo;
            await post_method(url, this.form, { headers }).then(() => {
                //Perform Success Action
                if (this.$store.getters.getHttpError) {
                    this.set_custom_place_of_issue_value()
                    let error_obj = this.$store.getters.getHttpError
                    this.msg = error_obj.message;
                    if (error_obj.response.data) this.form_errors = error_obj.response.data;

                }

                // else this.$router.replace('/client/');
                else this.$router.replace({ name: 'client', })
            });


        },
        async getData() {
            const options_response = await this.axios.get(`/api/place-of-issue-choices/`);
            this.placeOfIssueOptions = options_response.data;
            const response = await this.axios.options('/api/client/');


            if (response) {
                this.nationality_choices = response.data.actions.POST.nationality.choices;
                this.marital_status_choices = response.data.actions.POST.marital_status.choices;
            }
            // set the data returned as tasks
            if (this.form.id) {
                const response = await this.axios.get(`/api/client/${this.form.id}/`);
                // set the data returned as tasks
                if (response) 
                {
                    this.form = response.data;              
                    this.set_custom_place_of_issue_value()
                }

            }
            if (this.$store.getters.getHttpError) this.msg = this.$store.getters.getHttpError.message;

        },
    },
    async created() {
        this.form.id = this.$route.params.id;
        
        await this.getData();
        this.initialForm = JSON.parse(JSON.stringify(this.form));
    },
}
</script>
